import React, {Component} from 'react';
import './menu.scss';
import { TweenLite, Power4, gsap } from 'gsap';
import { HashLink as Link } from 'react-router-hash-link';
import { CSSPlugin } from 'gsap/CSSPlugin'
import { faShower } from '@fortawesome/free-solid-svg-icons';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        gsap.registerPlugin(CSSPlugin)

        // menu
        this.tweenedMenu = null;

        // menu items
        this.tweenedItem1 = null;
        this.tweenedItem2 = null;
        this.tweenedItem3 = null;
        this.tweenedItem4 = null;
        this.tweenedItem5 = null;
        this.tweenedItem6 = null;
        this.tweenedItem7 = null;
        this.tweenedItem8 = null;
        this.tweenedItem9 = null;
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.animateMenu();
      
        
    }




    animateMenu = () => {
        !this.props.toggled ? this.tweenListItemsOut() : this.tweenListItemsIn();

    }


    tweenListItemsIn()
    {

        TweenLite.to(this.tweenedMenu, 1.3, {transform: 'translateX(0)', ease: Power4.easeInOut})

        let animateInParams = {
            x: 0,
            opacity: 1,
            ease: Power4.easeInOut
        }

        TweenLite.to(this.tweenedItem1, 1, animateInParams).delay(.4);
        TweenLite.to(this.tweenedItem2, 1, animateInParams).delay(.45);
        TweenLite.to(this.tweenedItem3, 1, animateInParams).delay(.5);
        TweenLite.to(this.tweenedItem4, 1, animateInParams).delay(.55);
        TweenLite.to(this.tweenedItem5, 1, animateInParams).delay(.6);
        TweenLite.to(this.tweenedItem6, 1, animateInParams).delay(.65);
        TweenLite.to(this.tweenedItem7, 1, animateInParams).delay(.7);
        TweenLite.to(this.tweenedItem8, 1, animateInParams).delay(.75);
        TweenLite.to(this.tweenedItem9, 1, animateInParams).delay(.8);
        TweenLite.to(this.tweenedItem10, 1, animateInParams).delay(.8);
        TweenLite.to(this.tweenedItem11, 1, animateInParams).delay(.8);

    }

    tweenListItemsOut()
    {
        let animateOutParams = {
            x: -40,
            opacity: 0,
            ease: Power4.easeInOut
        }

        TweenLite.to(this.tweenedMenu, .8, {transform: 'translateX(-100%)', ease: Power4.easeInOut}).delay(.6)
        TweenLite.to(this.tweenedItem1, 1, animateOutParams).delay(.5);
        TweenLite.to(this.tweenedItem2, 1, animateOutParams).delay(.45);
        TweenLite.to(this.tweenedItem3, 1, animateOutParams).delay(.4);
        TweenLite.to(this.tweenedItem4, 1, animateOutParams).delay(.35);
        TweenLite.to(this.tweenedItem5, 1, animateOutParams).delay(.30);
        TweenLite.to(this.tweenedItem6, 1, animateOutParams).delay(.25);
        TweenLite.to(this.tweenedItem7, 1, animateOutParams).delay(.2);
        TweenLite.to(this.tweenedItem8, 1, animateOutParams).delay(.15)
        TweenLite.to(this.tweenedItem9, 1, animateOutParams).delay(.1)
        TweenLite.to(this.tweenedItem10, 1, animateOutParams).delay(.1)
        TweenLite.to(this.tweenedItem11, 1, animateOutParams).delay(.1)

    }
    
    toggleClass = e =>
    {
        e.preventDefault();

        // e.target.classList.toggle('fixed')

        /**
         * @see Header.js
         * Passado como Prop pelo componente pai
        */

        this.props.update( !this.props.toggled );

        // e.target.classList.toggle('menu-open');
        var buttonMenu = document.getElementById('teste');
        buttonMenu.classList.remove("menu-open");
        var x = document.getElementById("menu");
        x.style.display = "none";




      

    }

    render() {

        return(
            <nav className="main-menu" id="menu" ref={nav => this.tweenedMenu = nav} style={{display: "none"}}>
                <ul className="menu-list">
                    <li className="smaller-item" onClick={this.toggleClass}  ref={item1 => this.tweenedItem1 = item1}><Link  to="/#topo">Home</Link></li>
                    <li className="smaller-item" ref={item2 => {this.tweenedItem2 = item2}}><Link to="/sobre">Sobre Nós</Link></li>
                    <li className="bigger-item"  onClick={this.toggleClass} ref={item3 => {this.tweenedItem3 = item3}}><Link to="/#advertising">ADVERTISING</Link></li>
                    <li className="bigger-item"  onClick={this.toggleClass} ref={item4 => {this.tweenedItem4 = item4}}><Link key="cine-e-tv" to="/#institucional">INSTITUCIONAL</Link></li>
                    <li className="bigger-item"  onClick={this.toggleClass} ref={item5 => {this.tweenedItem5 = item5}}><Link key="publicidade" to="/#digital">DIGITAL</Link></li>
                    <li className="bigger-item"  onClick={this.toggleClass} onClick={this.toggleClass} ref={item6 => {this.tweenedItem6 = item6}}><Link key="branded-content" to="/#audiovisual">AUDIOVISUAL</Link></li>
                    {/* <li className="smaller-item" ref={item8 => this.tweenedItem8 = item8}><Link to="/portfolio">Portfólio</Link></li> */}
                    <li className="smaller-item" ref={item9 => {this.tweenedItem9 = item9}}><Link to="/contato">Contato</Link></li>
                    <li className="smallest-items" ref={item10 => {this.tweenedItem10 = item10}}>
                        <a href="mailto:contato@agenciasaving.cc" className="smallest-item">contato@agenciasaving.cc</a>
                        <span className="separator" aria-hidden="true">|</span>
                        <a href="tel:2135112399" className="smallest-item">+55 21 3511.2399</a>
                        <span className="separator" aria-hidden="true">|</span>
                        <a href="http://api.whatsapp.com/send?phone=5521998913113" target="_blank" className="smallest-item">+55 21 99891.3113</a>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default Menu;
