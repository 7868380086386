import React, { Component } from 'react'
import Wrapper from '../common/Wrapper';
import './customers.scss';
import WOW from 'wow.js';
import Slider from 'react-slick';
import '../../scss/slick.scss';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className="next"
        onClick={onClick}
        >
        {">"}
    </div>
    );
}
  
  
function SamplePrevArrow(props) {
    const { className,  onClick } = props;
    return (
      <div
        className="prev"
       
        onClick={onClick}
      >
         {"<"}
      </div>
    );
}
  
class Customers extends Component {
    constructor(props) {
        super(props)
        let logos = props.logos;
        this.logos = logos;

        this.logoMap = this.logos.map((logo, index) =>
            <div  key={index} data-key={index}>
                <img src={logo} alt="Logotipo de Parceiro" className="wow fadeInDown" data-wow-duration=".75s" data-wow-delay={`${index / 2.5}s`} />
            </div>
        )
   

        this.isMobile = window.innerWidth > 1000 ? false : true;

        this.slickOpts = {
            slidesToShow: 6, 
            slidesToScroll: 1, 
            dots: false,
            infinite: true, 
            speed: 600,
            // centerMode: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            arrows: true,
            responsive: [
              
               
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        }
    }

    componentDidMount() {
        const wow = new WOW();
        wow.init();
    }

    render() {
        return (
            <section className="custom-line-logos">
                <Wrapper >
                    <h2 className="d-block w-100 t-xg reg t-white margin-exxg upper t-centered wow fadeInDown" data-wow-duration="1s">Alguns de Nossos Clientes</h2>
                    {
                        [
                        <Slider { ...this.slickOpts } key="0">
                            {this.logoMap} 
                        </Slider>,
                      
                        <p className="t-opaque t-r w-100 t-centered d-lg-none" key="1"><img src={`${process.env.PUBLIC_URL}/images/draggable.svg`} alt="Deslize para ver mais" /></p>
                        
                    ]
                        
                        
                    }
                </Wrapper>
            </section>
        )
    }

}

export default Customers