import React, { Component } from 'react';

class MenuToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // menuToggled: false
        }
    }

    toggleClass = e =>
    {
        e.preventDefault();

        e.target.classList.toggle('fixed')

        /**
         * @see Header.js
         * Passado como Prop pelo componente pai
        */

        this.props.update( !this.props.toggled );

        e.target.classList.toggle('menu-open');

        var x = document.getElementById("menu");


        if(document.getElementById("menu").style.display == ""){
            x.style.display = "flex";

        }
        else if(document.getElementById("menu").style.display == "none"){
            x.style.display = "flex";
        }
        else{
            x.style.display = "none";
        }
        
    }

    render() {
        return (
            <button
            onClick={this.toggleClass}
            className="hamburger-toggle"
            aria-label="Abrir menu principal" id="teste">
            
                <span></span>
                <span></span>
                <span></span>
            </button>
            
        )
    }
}

export default MenuToggle;