import React, { Component } from 'react'

class Blocker extends Component {
    render() {
        return (
            <div className="blocker" />
        )
    }
}

export default Blocker