import API from './vimeoAPI';

export default class VimeoRequest
{
    constructor() {
        this.api = API;
        this.userID = '172751';
    }

    getVideo = async (video) => {
        return await this.api.get(`/videos/${video}`)
    }

    getChannelVideos = async (channelID) => {
        return  await this.api.get(`/channels/${channelID}/videos`)
    }

    getEmbedLinks = (ids) => {
        let embedLinks = [];

        ids.forEach(link => {
            embedLinks.push( `https://player.vimeo.com/video/${link.uri.replace('/videos/', '')}` )
        });

        return embedLinks;
    }

    generateEmbedLink = (vidID) => {
        return `https://player.vimeo.com/video/${vidID}`
    }
}