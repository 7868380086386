import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import VideoOverlay from '../layout/VideoOverlay';
import Blocker from '../common/blocker';


class MediaItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            overlayOpen: false,
            overlayPlayState: false,
        }
    }

    openOverlay = () => {
        this.setState({
            overlayOpen: true,
            overlayPlayState: true,
        })
    }

    closeOverlay = () => {
        this.setState({
            overlayOpen: false,
            overlayPlayState: false,
        })
    }

    componentDidMount() {
        // Escape key Listener to close overlay
        document.addEventListener('keyup', e => {
            if ( e.keyCode === 27 ) {
                this.closeOverlay();
            }
        });
    }

    render() {
        if ( this.props.type === 'img' ) {
            return (
                <div className={`media-item ${this.props.className} ${this.props.type === 'img' ? 'image-item' : 'video-item'}`} >
                    {

                        this.props.noBox === undefined ?

                        <ReactFancyBox thumbnail={this.props.url} image={this.props.url} playsInline />

                        :

                        <img src={this.props.url} alt="" />
                    }
                </div>
            )
        } else {
            return(
                [
                <div onClick={ this.openOverlay } className="becomes-overlay" key={0}>
                    { 
                        this.props.noOverlay === true ? 
                        <ReactPlayer { ...this.props }  controls={true}/> :   
                        [
                        <Blocker key={0} />,
                        <ReactPlayer { ...this.props }  controls={true} key={1} />,
                        <VideoOverlay isOpen={this.state.overlayOpen} currentlyPlaying={this.props.url} playState={this.state.overlayPlayState} key={2} />
                        ]
                    }
                </div>,
                <button className={this.state.overlayOpen !== false ? 'close-overlay shown' : 'close-overlay' }
                onClick={ this.closeOverlay } aria-label="Fechar overlay de vídeo e parar reprodução" key={1}>
                    &times;
                </button>
                ]
            )
        }
    }
}

export default MediaItem;