import React, { Component } from 'react';
import './header.scss'
import Wrapper from './Wrapper';
import Menu from './Menu';
import MenuToggle from './MenuToggle';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';

class Header extends Component {
	constructor(props) {
		super(props);

		// for Swal handling
		window.submitted = false;

		this.state = {
			toggled: false,
			update : toggled => {
				this.setState({toggled: toggled})
			}
		}

		const tagManagerArgs = {
			gtmId: 'GTM-MBZVSTW'
		}

		TagManager.initialize(tagManagerArgs);
	}

	update = toggled => {
		this.setState({toggled : toggled});
	}

	render() {
		return (
			<header className="masthead">
				<Wrapper flexRules={'bigger flexed wrap row jf-between ai-center'}>
					<MenuToggle { ...this.state } ref={cd => window.menuToggle = cd } />
					<Link to="/" title="Voltar à home" className="logo-header">
						<img src={`${process.env.PUBLIC_URL}/images/novo-logo.png`} alt="Logotipo Saving" className="logo-header" />
					</Link>
					<Link to="/contato" className="contact-route">
						{/* <span className="t-2g t-white">CONTATO</span> */}
						<p>CONTATO</p>
					</Link>
				</Wrapper>
				<Menu toggled={this.state.toggled} { ...this.state } />
			</header>
		)
	}
}

export default Header;