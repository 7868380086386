import React from 'react';
import Header from './components/common/Header';
import Opening from './components/sections/Opening';
import Customers from './components/sections/Customers';
import Work from './components/sections/Work';
import LastProjects from './components/sections/LastProjects';
import Contact from './components/sections/Contact';
import Footer from './components/common/Footer';

// Array of logo URLS for customers section

const base = `${process.env.PUBLIC_URL}/images`; 
// const logos = [`${base}/petros.png`, `${base}/prumo.png`, `${base}/academy.png`, `${base}/gna.png`, `${base}/gruporao.png`,`${base}/ladyluck.png`, `${base}/porto.png`];
const logos = [
`${base}/petros.png`,
`${base}/br-mais.png`, 
`${base}/EPOCA.png`, 
`${base}/Logo-IRB-Branca-of.png`, 
`${base}/fortlev.png`, 
`${base}/BAT.png`, 
`${base}/DIOR.png`, 

`${base}/FQM.png`, 
`${base}/KAROON.png`, 

// `${base}/logo-supervia.png`, 
//  `${base}/enge.png`,  
//  `${base}/prumo.png`,  
//  `${base}/gna.png`, 
//  `${base}/porto.png`
];


// Last projects 
const lastProjectProps = {
  sectionTitle : "",
}

function App() {
  return (
    <div className="App">
      <Header key={0}/>
      <Opening key={1} />
      <Customers key={2} logos={logos} />
      <Work key={3} />
      <LastProjects key={4} {...lastProjectProps} />
      <Contact key={5} />
      <Footer key={6} />
    </div>
  );
}

export default App;
