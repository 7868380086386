import React, { Component } from 'react'
import Wrapper from './common/Wrapper';
import Contact from './sections/Contact';
import Header from './common/Header';
import Footer from './common/Footer';
import './about.scss';
import WOW from 'wow.js';

class About extends Component {

    componentDidMount() {
        const wow = new WOW();
        wow.init();
    }

    render() {
        return (
            <div className="app-content about">
                <Header />
                <section className="about-intro">
                    <Wrapper flexRules="flexed row wrap keeps-flex">
                        <div className="text-content">
                            <h1 className="title-secondary t-white upper wow fadeInDown" data-wow-duration="1s">Fazemos de tudo <br />para contar <br /> <span className="t-primary">boas histórias</span> </h1>
                        </div>
                    </Wrapper>
                </section>
                <section className="about-body">
                    <Wrapper flexRules="flexed row wrap jf-between ai-start pos-r">
                        <div className="prop-notebook-uncontained-container half wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".25s">
                            <img src={`${process.env.PUBLIC_URL}/images/prop-notebook.png`} className="prop-notebook-uncontained" alt="" />
                        </div>
                        <div className="text-content wow fadeInRight" data-wow-duration="1s" data-wow-delay=".35s">
                            <p className="t-xxg t-gray margin-exxxg">A <span className="sb">Saving</span> é uma agência full-service. Investimos na mistura criativa de branding, design, comunicação institucional e audiovisual. </p>
                            <p className="t-xxg t-gray">Nossa estrutura nos permite abordar projetos de ponta-a-ponta. Com gestão in-house de planejamento, criação e execução de campanhas e conteúdos de alta performance.</p>
                        </div>
                        <h2 className="section-tagline w-100 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".65s">
                            Somos uma empresa especializada em criar e produzir histórias eficazes e autênticas. Desde o planejamento à execução, traçamos estratégias customizadas, com foco nos objetivos dos nossos clientes.
                    </h2>
                    </Wrapper>
                </section>
                <Contact />
                <Footer />
            </div>
        )
    }

}

export default About;