import React, { Component } from 'react'
import Wrapper from '../common/Wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVimeo, faFacebook, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import './footer.scss';
import WOW from 'wow.js';

class Footer extends Component {
    componentDidMount() {
        const wow = new WOW();
        wow.init();
    }

    render() {
        return (
            [
                <footer id="hull" className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".75s" key={0}>
                    <Wrapper flexRules="flexed row wrap ai-center">
                        <p className="t-r t-white">
                            © Agência Saving <span className="separator">|</span> {new Date().getFullYear()} Todos os direitos Reservados
                    <span className="separator">|</span> Nos Siga &nbsp;<a href="https://vimeo.com/agenciasaving" target="_BLANK" rel="noopener noreferrer" className="t-r t-white"><FontAwesomeIcon icon={faVimeo} /></a>
                    &nbsp; <a href="https://www.facebook.com/agenciasaving/" target="_BLANK" rel="noopener noreferrer" title="nosso facebook" className="t-r t-white"><FontAwesomeIcon icon={faFacebook} /></a> &nbsp; <a href="https://www.instagram.com/savingcomunicacao/" target="_BLANK" rel="noopener noreferrer" title="Nosso Instagram" className="t-r t-white"><FontAwesomeIcon icon={faInstagram} /></a> &nbsp;
                    <a href="https://www.linkedin.com/company/saving-comunicacao" target="_BLANK" rel="noopener noreferrer" title="Nosso Linkedin" className="t-r t-white"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                        </p>
                    </Wrapper>
                </footer>,
                <a className="whatsapp-cta-wrapper" href="//api.whatsapp.com/send?phone=5521998913113" target="_BLANK" rel="noopener noreferrer" key={1}
                        title="Nosso Whatsapp" onClick={() => {  window.ga("gtm1.send", "event", "WhatsApp", "Click", "WhatsApp") } }>
                    <img src={`${process.env.PUBLIC_URL}/images/whatsapp.png`} alt="Ícone do Whatsapp branco em fundo verde" />
                </a>,
                <a href="https://www.3xceler.com.br/criacao-de-sites" rel="noopener noreferrer" target="_BLANK" className="t-r signature" key={2}>Criação de Sites: <img src="/images/3xceler.png" alt="Logotipo 3xceler" title="Logotipo 3xceler" className="xceler-logo" /></a>
            ]
        )
    }
}

export default Footer;