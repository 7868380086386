import React, { Component } from 'react';
import Wrapper from '../common/Wrapper';
import './lastProjects.scss';
import { Link } from 'react-router-dom';
import VimeoRequest from '../functions/vimeoRequests';
import MediaItem from '../layout/MediaItem';
import '../layout/videoreel.scss'
import WOW from 'wow.js';
import Slider from 'react-slick';
import '../../scss/slick.scss';

class LastProjects extends Component {
    constructor(props) {
        super(props);

        this.state = {
            verticalVideos: [],
            horizontalVideos: []
        }

        this.isMobile = window.innerWidth > 1000 ? false : true;

        this.slickOpts = { slidesToShow: 6, slidesToScroll: 1, autoplay: true, autoplaySpeed: 3000, speed: 800, arrows: false, dots: false };
    }

    componentDidMount = () => {
        this.handleVimeoRequests();
        const wow = new WOW();
        wow.init();
    }

    componentDidUpdate(prevProps, prevState) {
        this.setAsideHeight();
    }

    handleVimeoRequests = () => {
        let request = new VimeoRequest(),
            channelVids = request.getChannelVideos('1572444'),
            verticalVideos = [],
            horizontalVideos = [];

        channelVids.then(response => {
            let embedLinks = request.getEmbedLinks(response.data.data)
            let verticalSlice = embedLinks.slice(0, 4);
            let horizontalSlice = response.data.data.slice(5, embedLinks.length);

            verticalSlice.forEach(embedLink => {
                verticalVideos.push(embedLink);
            })

            horizontalSlice.forEach(videoObj => {
                horizontalVideos.push({
                    url: videoObj.uri.replace('/videos/', ''),
                    description: videoObj.description,
                    title: videoObj.name
                });
            })

            this.setState({
                verticalVideos: verticalSlice,
                horizontalVideos: horizontalVideos
            })
        })
    }

    renderVerticalVideos = () => {
        return (
            this.state.verticalVideos.map((video, idx) =>
                <div className="overflow-box" data-key={idx} key={idx}>
                    <div className="keep-aspect aspect-16-9">
                        <MediaItem width="100%" height="100%" volume={.7} url={video} />
                    </div>
                </div>
            )
        )
    }

    renderHorizontalVideos = () => {
        return (

            <div className="pos-r carousel-wrapper">
                <Slider className="horizontal-swipe" {...this.slickOpts}>
                    {this.state.horizontalVideos.map((value, idx) =>
                        <div className="video-reel-overlay-wrapper" key={idx}>
                            <MediaItem url={`https://player.vimeo.com/video/${value.url}`} noOverlay={true} volume={.7} width="100%" height="100%" controls={true} />
                        </div>
                    )}
                </Slider>
                {/* <img src={`${process.env.PUBLIC_URL}/images/prop-shape-carousel.png`} className="prop-carousel" alt="" /> */}
                {/* <img src={`${process.env.PUBLIC_URL}/images/prop-shape-carousel.png`} className="prop-carousel-inverted" alt="" /> */}
            </div>

        )
    }

    setAsideHeight = () => {
        if (window.innerWidth > 1000) {
            const reelVertical = document.querySelector('.video-reel-vertical');
            reelVertical.style.height = `${reelVertical.offsetHeight + 175}px`
        }
    }

    render() {

        const { verticalVideos, horizontalVideos } = this.state;

        return (
            <section className="last-projects">
                <Wrapper flexRules="flexed wrap row jf-between">
                    <div className="text-presentation">
                        <h2 className="title-primary t-primary upper margin-g wow fadeInLeft" data-wow-duration="1s">{this.props.sectionTitle}</h2>
                        {/* { this.props.paragraphs.map( (paragraph, idx) => <p key={idx} className="t-white t-xg margin-exg">{paragraph}</p> ) } */}
                        {!this.isMobile ? <div className="group w-100 wow fadeIn" data-wow-duration="1s">
                            <Link className="btn-arrowed" to="/portfolio"> CASES | AUDIOVISUAL <span>→</span> </Link>
                        </div> : null}
                    </div>
                    <aside className="video-reel-vertical">
                        {verticalVideos.length ? this.renderVerticalVideos() : <span> Carregando ... </span>}
                    </aside>
                    {this.isMobile ? <div className="group w-100 wow fadeIn" data-wow-duration="1s">
                        <Link className="btn-arrowed" to="/portfolio"> CASES | AUDIOVISUAL <span>→</span> </Link>
                    </div> : null}
                </Wrapper>
                {horizontalVideos.length ? this.renderHorizontalVideos() : <span> Carregando ... </span>}
            </section>
        )
    }
}

export default LastProjects;