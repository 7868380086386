import React, { Component } from 'react'
import Wrapper from './../common/Wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './contact.scss';
import WOW from 'wow.js';
import axios from 'axios';
import SweetAlert from 'sweetalert2-react';
import InputMask from 'react-input-mask';


class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: window.submitted,
        }

    }

    submitForm = (event) => {

        const formElement = event.target;
        const formData = new FormData(formElement);
        let dataToSubmit = {};
        event.preventDefault();

        for (var pair of formData.entries()) {
            dataToSubmit[pair[0]] = pair[1]
        }

        const requestOptions = { headers: { 'content-type': 'multipart/form-data', } }

        const localPath = '//localhost/saving/public/ajax/index.php';
        const serverPath = '//agenciasaving.cc/ajax/index.php';
        const envPath = window.location.origin.indexOf('localhost') > 1 ? localPath : serverPath;

        axios.post(envPath, dataToSubmit, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ show: true })
                    if (window.ga !== undefined) {
                        window.ga("gtm1.send", "event", "Formulário", "Envio", "Formulários de Contato")
                    }
                }
            })
    }

    componentDidMount() {
        const wow = new WOW();
        wow.init();

    }

    render() {
        return (
            <section className="contact-ending pos-r">
                <Wrapper flexRules="flexed row wrap jf-between">
                    <div className="contact-text flexed column jf-between wrap">
                        <h2 className="t-white title-primary upper margin-exxxg wow fadeInLeft" data-wow-duration=".75s">Vamos <br /> Conversar?</h2>
                        <p className="t-r t-white margin-exxxg wow fadeInLeft" data-wow-duration=".75s" data-wow-delay=".25s">Fale com a gente através dos canais abaixo ou preencha o formulário.</p>
                        <div className="flexed row wrap jf-between ai-center keeps-flex contact-numbers">
                            <p className="t-xg t-white flexed row wrap ai-center half wow fadeInLeft" data-wow-duration=".75s" data-wow-delay=".35s">
                                <img className="phone-icon" src={`${process.env.PUBLIC_URL}/images/icon-phone-primary.png`} alt="ícone de telefone" />
                                <a title="Fale Conosco" href="tel:552135112399">+55 21 3511.2399</a>
                            </p>
                            <p className="t-xg t-white flexed row wrap ai-center half wow fadeInLeft" data-wow-duration=".75s" data-wow-delay=".55s">
                                <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
                                <a title="Fale Conosco" onClick={() => {  window.ga("gtm1.send", "event", "WhatsApp", "Click", "WhatsApp") } } href="http://api.whatsapp.com/send?phone=5521998913113" target="_BLANK" rel="noopener noreferrer">+55 21 99891.3113</a>
                            </p>
                            {
                                window.innerWidth > 1000 ? 
                                <a href="https://goo.gl/maps/vsDC2Foy1AgQLBcW8" target="_BLANK" rel="noopener noreferrer" className="t-g address t-white flexed row wrap ai-center w-100 wow fadeInLeft" data-wow-duration=".75s" data-wow-delay=".75s"> Rua Voluntários da Pátria 301 | Botafogo | sala 902 | RJ</a> 
                                : null
                            }
                        </div>
                    </div>
                    <div className="contact-form">
                        <form className="contact-ending-form flexed row wrap ai-center jf-between wow fadeInRight" data-wow-duration="1.2s" data-wow-delay=".65s" onSubmit={this.submitForm}>
                            <label className="full">
                                <p className="t-r t-white margin-s">Nome:</p>
                                <input className="field-white" type="text" name="Nome" required />
                            </label>
                            <label className="half">
                                <p className="t-r t-white margin-s">E-mail:</p>
                                <input className="field-white" type="email" name="E-mail" required />
                            </label>
                            <label className="half">
                                <p className="t-r t-white margin-s">Telefone:</p>
                                <InputMask name="Telefone" className="field-white" type="tel" required mask="(99) 99999.9999" />
                            </label>
                            <label className="half">
                                <p className="t-r t-white margin-s">Empresa:</p>
                                <input className="field-white" type="text" name="Empresa" required />
                            </label>
                            <label className="half">
                                <p className="t-r t-white margin-s">Assunto:</p>
                                <select className="field-white" name="Assunto" required>
                                    <option value="">Selecione</option>
                                    <option value="Social Media">Social Media</option>
                                    <option value="Institucional">Institucional</option>
                                    <option value="Comunicação Interna">Comunicação Interna</option>
                                    <option value="Audiovisual">Audiovisual</option>
                                    <option value="Criação de Sites">Criação de Sites</option>
                                    <option value="Advertising">Advertising</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </label>
                            <label className="full">
                                <p className="t-r t-white margin-s">Mensagem:</p>
                                <textarea className="field-white bigger" name="Mensagem" required></textarea>
                            </label>
                            <button className="btn-primary upper t-white bold t-xg">ENVIAR <FontAwesomeIcon icon={faPaperPlane} /> </button>
                        </form>
                        {
                            window.innerWidth < 1000 ? 
                            <a href="https://goo.gl/maps/vsDC2Foy1AgQLBcW8" target="_BLANK" rel="noopener noreferrer" className="t-g address t-white flexed row wrap ai-center w-100 wow fadeInLeft" data-wow-duration=".75s" data-wow-delay=".75s"> Rua Voluntários da Pátria 301 | Botafogo | sala 902 | RJ</a> 
                            : null
                        }
                    </div>
                </Wrapper>
                <div className="uncontained-zebra-pattern-reverse" />
                <SweetAlert
                    show={this.state.show}
                    title={'E-mail enviado com sucesso!'}
                    text={'Retornaremos seu contato o mais breve possível.'}
                    onConfirm={() => this.setState({ show: false })}
                />
            </section>
        )
    }

}

export default Contact