import React, { Component } from 'react'
import Wrapper from '../common/Wrapper';
import './work.scss';
import WOW from 'wow.js';

class Work extends Component {

    constructor(props) {
        super(props)

        this.isMobile = window.innerWidth > 1000 ? false : true;
    }

    componentDidMount() {
        const wow = new WOW();
        wow.init();
    }

    render() {
        return (
            <section className="work">
                {/* <div className="uncontained-zebra-pattern" /> */}
                <Wrapper flexRules="flexed row wrap jf-between ai-center">
                    <div className="title-divided flexed row wrap jf-between ai-center">
                        <h2 className="title-primary t-white upper wow fadeInLeft" data-wow-duration="1s">o que fazemos</h2>
                        <p className="description-companion t-white t-xxg wow fadeInRight" data-wow-duration="1s" data-wow-delay=".15s">Direcionamos empresas tradicionais em sua evolução digital. Criamos soluções de marketing para acelerar o crescimento de empresas B2B e B2C. Desde o planejamento à execução, traçamos estratégias customizadas, com foco nos objetivos dos nossos clientes.</p>
                    </div>
                    <div className="work-instance odd flexed row wrap jf-between ai-end advertising" id="advertising">
                        <img src={`${process.env.PUBLIC_URL}/images/advertising.jpg`} alt="Advertising" className="work-image wow fadeInUp" data-wow-duration="1s" title="Advertising" />
                        <div className="content-box">
                            <h3 className="title-stroked t-stroke upper wow fadeInUp" data-wow-duration=".75s">Advertising</h3>
                            <p className="t-xg t-white wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".4s">As empresas hoje possuem diversos desafios que ultrapassam e vão muito além de um conceito criativo excepcional. Como por exemplo, está dentro do budget? Como se adequar e reagir as plataformas? Como monitorar o impacto e segmentação? Acredite. Nós te entendemos.</p>
                            <p className="t-xg t-white wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".6s">Nossa estrutura nos permite abordar projetos de ponta-a-ponta. Com gestão in-house de criação, planejamento de mídia e monitoramento.</p>
                        </div>
                    </div>
                    <div className="work-instance even flexed row wrap jf-between ai-end institucional" id="institucional">
                        <div className="content-box">
                            <h3 className="title-stroked t-stroke upper wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".4s">Institucional</h3>
                            <p className="t-xg t-white wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".6s">Vestimos a camisa da sua organização para entender <br /> e viver de perto cada cultura organizacional e desafios de comunicação. </p>
                            <p className="t-xg t-white wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".8s">Com nossa estrutura criativa in-house, conseguimos planejar, criar e executar campanhas de comunicação interna e externa para empresas, a curto, médio e longo prazo. Mesclar Online e Off-line é nossa especialidade.</p>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/images/institucional.jpg`} alt="Institucional" className="work-image wow fadeInUp" data-wow-duration="1s" title="Institucional" />
                    </div>
                    <div className="work-instance odd flexed row wrap jf-between ai-start digital" id="digital">
                        <img src={`${process.env.PUBLIC_URL}/images/digital.jpg`} alt="digital" className="work-image wow fadeInUp" data-wow-duration=".75s" title="digital" />
                        <div className="content-box">
                            <h3 className="title-stroked t-stroke upper wow fadeInUp" data-wow-duration=".75s">Digital</h3>
                            <p className="t-xg t-primary wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".4s">Inbound Marketing</p>
                            <p className="t-xg t-white wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".6s">Em 2021 vencer no ecossistema digital exige dominarmos técnicas e táticas multidisciplinares que englobam desde a análise de dados e gestão ágil de processos criativos. Hoje, você precisa estar onde sua audiência está, e gerar oportunidades de negócio nesses canais. O inbound marketing contempla uma solução completa para o seu funil de marketing, cria oportunidades reais de vendas e posicionamento de imagem.</p>
                            <p className="t-xg t-white wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".8s">Nossa metodologia integrada contempla estratégias e planos de marketing com SEO, automação, e-mail marketing, compra de mídia, design, web analytics, conteúdo e growth marketing. </p>
                            <p className="t-xg t-primary wow fadeInUp" data-wow-duration=".75s" data-wow-delay="1s">Social Media</p>
                            <p className="t-xg t-white wow fadeInUp" data-wow-duration=".75s" data-wow-delay="1.2s">Nosso foco é criar conteúdos que atinjam o centro emocional das pessoas. E hoje, isso significa muito mais do que simplesmente cumprir um briefing. Criamos, coordenamos e executamos projetos de conteúdo criativo, gestão reputacional, monitoramento de marcas, construção de assets para os desafios do seu negócio.</p>
                        </div>
                    </div>
                    <div className="work-instance even flexed row wrap jf-between ai-center audiovisual" id="audiovisual">
                        <div className="content-box">
                            <h3 className="title-stroked t-stroke upper wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".4s">audiovisual</h3>
                            <p className="t-xg t-white wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".6s">Vídeos institucionais, animações, vídeos infográficos, after movies, short-films, vídeo depoimento, entre outros formatos. Investimos sempre na mistura criativa de vídeo, motion, design, branding e digital.  </p>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/images/audiovisual.jpg`} alt="audiovisual" className="work-image wow fadeInUp" data-wow-duration="1s" title="audiovisual" />
                    </div>
                </Wrapper>
            </section>
        )
    }

}

export default Work