import React, { Component } from 'react'
import Header from './common/Header';
import Footer from './common/Footer';
import Wrapper from './common/Wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './contactPage.scss';
import WOW from 'wow.js';
import SweetAlert from 'sweetalert2-react';
import axios from 'axios';
import InputMask from 'react-input-mask';


class ContactPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
        }
    }

    submitForm = (event) => {

        const formElement = event.target;
        const formData = new FormData(formElement);
        let dataToSubmit = {};
        event.preventDefault();

        for (var pair of formData.entries()) {
            dataToSubmit[pair[0]] = pair[1]
        }

        const requestOptions = { headers: { 'content-type': 'multipart/form-data', } }

        const localPath = '//localhost/saving/public/ajax/index.php';
        const serverPath = '//agenciasaving.cc/ajax/index.php';
        const envPath = window.location.origin.indexOf('localhost') > 1 ? localPath : serverPath;

        axios.post(envPath, dataToSubmit, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ show: true })
                    if (window.ga !== undefined) {
                        window.ga("gtm1.send", "event", "Formulário", "Envio", "Formulários de Contato")
                    }
                }
            })
    }


    componentDidMount() {
        const wow = new WOW();
        wow.init();
    }

    render() {
        return (
            <div className="app-content contactpage">
                <Header />
                <section className="contactpage-body">
                    <Wrapper flexRules="flexed row wrap spaced start contactpage-grid">
                        <h1 className="title-primary t-white upper margin-2xxg wow fadeInUp" data-wow-duration=".75s">
                            Vamos <br />
                        Conversar?
                    </h1>
                        <p className="t-white t-r margin-2xg wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".35s">Fale com a gente através dos canais abaixo ou preencha o formulário.</p>
                        <div className="flexed row wrap ai-center w-100 contact-means margin-2xxg keeps-flex contact-numbers">
                            <p className="t-xg t-white flexed row wrap ai-center wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".55s">
                                <img className="phone-icon" src={`${process.env.PUBLIC_URL}/images/icon-phone-primary.png`} alt="" />
                                <a title="Fale Conosco" href="tel:552135112399">+55 21 3511.2399</a>
                            </p>
                            <p className="t-xg t-white flexed row wrap ai-center wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".75s">
                                <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
                                <a title="Fale Conosco" onClick={() => { window.ga("gtm1.send", "event", "WhatsApp", "Click", "WhatsApp") }} href="http://api.whatsapp.com/send?phone=5521998913113" target="_BLANK" rel="noopener noreferrer">+55 21 99891.3113</a>
                            </p>
                            {
                                window.innerWidth > 1000 ?
                                    <a href="https://goo.gl/maps/vsDC2Foy1AgQLBcW8" target="_BLANK" rel="noopener noreferrer" className="t-g address t-white flexed row wrap ai-center w-100 wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".75s"> Rua Voluntários da Pátria 301 | Botafogo | sala 902 | RJ</a>
                                    : null
                            }
                        </div>
                        <form className="contatpage-inner flexed wrap row jf-between ai-start" onSubmit={this.submitForm}>
                            <label className="w-100 full wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".35s">
                                <p className="t-r t-white margin-s">Nome:</p>
                                <input className="field-white" type="text" name="Nome" required />
                            </label>
                            <label className="half wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".45s">
                                <p className="t-r t-white margin-s">E-mail:</p>
                                <input className="field-white" type="text" name="E-mail" required />
                            </label>
                            <label className="half wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".45s">
                                <p className="t-r t-white margin-s">Telefone:</p>
                                <InputMask name="Telefone" className="field-white" type="tel" required mask="(99) 99999.9999" />
                            </label>
                            <label className="half wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".55s">
                                <p className="t-r t-white margin-s">Endereço:</p>
                                <input className="field-white" type="text" name="Endereço" required />
                            </label>
                            <label className="half wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".55s">
                                <p className="t-r t-white margin-s">Cidade:</p>
                                <input className="field-white" type="text" name="Cidade" required />
                            </label>
                            <label className="half wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".65s">
                                <p className="t-r t-white margin-s">Estado:</p>
                                <input className="field-white" type="text" name="Estado" required />
                            </label>
                            <label className="half wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".65s">
                                <p className="t-r t-white margin-s">CEP:</p>
                                <input className="field-white" type="text" name="CEP" required />
                            </label>
                            <label className="half wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".75s">
                                <p className="t-r t-white margin-s">Empresa:</p>
                                <input className="field-white" type="text" name="Empresa" required />
                            </label>
                            <label className="half wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".75s">
                                <p className="t-r t-white margin-s">Assunto:</p>
                                <select className="field-white" name="Assunto" required>
                                    <option value="">Selecione</option>
                                    <option value="Social Media">Social Media</option>
                                    <option value="Institucional">Institucional</option>
                                    <option value="Comunicação Interna">Comunicação Interna</option>
                                    <option value="Audiovisual">Audiovisual</option>
                                    <option value="Criação de Sites">Criação de Sites</option>
                                    <option value="Advertising">Advertising</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </label>
                            <label className="full wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".85s">
                                <p className="t-r t-white margin-s">Mensagem:</p>
                                <textarea className="field-white bigger" name="Mensagem" required></textarea>
                            </label>
                            <button className="btn-primary upper t-white bold t-xg wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".95s">ENVIAR <FontAwesomeIcon icon={faPaperPlane} /> </button>
                        </form>
                        {
                            window.innerWidth < 1000 ?
                                <a href="https://goo.gl/maps/vsDC2Foy1AgQLBcW8" target="_BLANK" rel="noopener noreferrer" className="t-g address t-white flexed row wrap ai-center w-100 wow fadeInUp" data-wow-duration=".75s" data-wow-delay=".75s"> Rua Voluntários da Pátria 301 | Botafogo | sala 902 | RJ</a>
                                : null
                        }
                    </Wrapper>
                </section>
                <Footer />
                <SweetAlert
                    show={this.state.show}
                    title={'E-mail enviado com sucesso!'}
                    text={'Retornaremos seu contato o mais breve possível.'}
                    onConfirm={() => this.setState({ show: false })}
                />
            </div>
        )
    }

}

export default ContactPage;