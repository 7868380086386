import React, { Component } from 'react';
import './opening.scss';
import Video from '../common/Video.js'

class Opening extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount = () => {
        const video = document.querySelector('video')
        video.setAttribute('playsinline', '')
        video.play()
    }

    render() {
        return(
            <section className="video-opening" id="topo">
                <div className="video-playback-wrapper">
                <Video
                    preload="preload"
                    poster={`${process.env.PUBLIC_URL}/images/poster-video.jpg`}
                    source={`${process.env.PUBLIC_URL}/opening-new-novo.mp4`}
                    autoplay
                    muted="muted"
                    loop="loop"
                />
                </div>
            </section>
        )
    }

}

export default Opening;