import React, { Component } from 'react'
import Header from './common/Header';
import Footer from './common/Footer';
import Contact from './sections/Contact';
import Wrapper from './common/Wrapper';
import MediaItem from './layout/MediaItem';
import VimeoRequest from './functions/vimeoRequests';
import './portfolio.scss';

class Portfolio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            videos: null
        
        }
    }

    componentDidMount() {
        this.request = new VimeoRequest();
        this.videos = this.request.getChannelVideos('1572444');
        this.videos.then( response => { 
            this.setState({
                videos: response.data.data
            })
        })

        if ( window.innerWidth < 1000 ) {
            document.querySelector('.scrolls-horizontally-on-mobile').addEventListener('mousewheel', this.scrollHorizontally);
            document.querySelector('.scrolls-horizontally-on-mobile').addEventListener('DOMMouseScroll', this.scrollHorizontally);
        }
    }

    // enables horizontal scrolling
    scrollHorizontally = (e) => {
        e = window.event || e;
        var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        document.querySelector('.scrolls-horizontally-on-mobile').scrollLeft -= (delta*40);
        e.preventDefault();
    }

    render() {
        return (
            <div className="app-content portfolio">
                <Header />
                <section className="portfolio-opening">
                    <div className="uncontained-zebra-pattern" aria-hidden="true" />
                    <Wrapper flexRules="flexed row wrap jf-between ai-start">
                        <h1 className="t-stroke title-highlight w-100">AUDIOVISUAL</h1>
                    </Wrapper>
                </section>
                <section className="portfolio-items">
                    <Wrapper flexRules="flexed row wrap jf-between ai-start portfolio-grid scrolls-horizontally-on-mobile keeps-flex">
                        {this.state.videos !== null ? this.state.videos.map((item, index) =>
                            <div className="portfolio-item half wow fadeInUp" key={index} data-wow-duration="1s" data-wow-delay={index / 2.5}>
                                <div className="frameholder margin-2r keep-aspect aspect-16-9">
                                    <MediaItem url={ `https://player.vimeo.com/video/${item.uri.replace('/videos/', '')}` } volume={.7} width="100%" height="100%" />
                                </div>
                                <h2 className="t-xg t-primary margin">{item.name}</h2>
                                <p className="t-r t-white item-description">{item.description !== null ? item.description : 'Sem descrição disponível'}</p>
                            </div>
                        ) : '<p className="t-r t-white">Carregando Portfólio...</p>'}
                        {/* <button className="btn-arrowed">MOSTRAR MAIS</button> */}
                    </Wrapper>
                </section>
                <Contact />
                <Footer />
            </div>
        )
    }

}

export default Portfolio;