import React, {Component} from 'react';

class Video extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
        }

        /**
         *
         * Defines if video is an iframe or a html5 <video></video>
        */
        const isFrame = props.isFrame !== null ?  props.isFrame : false;
        this.isFrame = isFrame;

        /**
         *
         * returns the correct html;
        */
        const videoElement = this.isFrame ? this.iframeVideo(this.props) : this.htmlVideo(this.props);
        this.videoElement = videoElement;


    }

    iframeVideo (props) {
        return(
            <iframe
                title={props.source}
                className={props.classes}
                src={props.source}
                controls={true}
            />
        )
    }

    htmlVideo (props) {
        return (
            <video
                className={props.classes}
                preload={props.preload}
                poster={props.poster}
                autoPlay={props.autoplay}
                muted={props.muted}
                loop={props.loop}
                // webkit-playsInline
                playsInline
                onMouseOver={ props.playOnHover ? this.playVideo : null }
            >
                <source src={props.source} />
            </video>
        )
    }

    // playVideo (evt) {
    //     let evtSrc = evt.target.src
    //     evtSrc += '&autoplay=1'
    // }

    render() {
        return(
            this.videoElement
        )
    }
}

export default Video;