import React, { Component } from 'react';
import './VideoOverlay.scss';
import ReactPlayer from 'react-player';

export default class VideoOverlay extends Component
{
    constructor(props, children) {
        super(props);

        this.state = {
            isOpen: props.isOpen,
            currentlyPlaying: this.props.currentlyPlaying,
            playState: this.props.playState,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if ( prevState.isOpen !== this.props.isOpen || prevState.playState !== this.props.playState ) {

            this.setState({
                isOpen: this.props.isOpen,
                playState : this.props.playState
            })
        }
    }

    render = () => {
        return(
            <div className={`player-overlay ${ this.state.isOpen === true ? 'shown' : '' }`}>
                <ReactPlayer url={this.props.currentlyPlaying} playing={this.state.playState} controls={true} />
            </div>
        )
    }
}