import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import About from './components/About';
import Portfolio from './components/Portfolio';
import ContactPage from './components/ContactPage';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
  <Switch>
      <Route path="/" exact={true} component={App} key={0} />
      <Route path="/sobre" exact={true} component={About} key={1} />
      <Route path="/portfolio" exact={true} component={Portfolio} key={2} />
      <Route path="/contato" exact={true} component={ContactPage} key={3} />
  </Switch>
</BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
